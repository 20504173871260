import React, { useState } from 'react';
import Nav from './Nav';
import { Link } from 'gatsby';
import logo from '../assets/img/lionlogo-black.png';

export default function SideBar({ fullMenu }) {
  const [headerOpen, toggleHeader] = useState(false);
  return (
    <>
      <header id="header" className={`${fullMenu ? '' : 'alt'}`}>
        <div className="brandContainer">
          <Link to="/" className="headerLogoLink">
            <img src={logo} className="headerLogo" alt="logo" />
          </Link>
          <h1>
            <Link to="/">Don Bosco Lion Dance</Link>
          </h1>
        </div>

        <nav>
          <Link to="/team" className="navbarLink">
            Team
          </Link>
          <Link to="/background" className="navbarLink">
            Background
          </Link>
          <Link to="/gallery" className="navbarLink">
            Gallery
          </Link>
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              toggleHeader(!headerOpen);
            }}
            className="menuToggle"
          >
            <span>Menu</span>
          </a>
        </nav>
      </header>
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
    </>
  );
}

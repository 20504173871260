module.exports = {
  siteTitle: 'Don Bosco Lion Dance', // <title>
  manifestName: 'DonBoscoLionDance',
  manifestShortName: 'DBLionDance', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#8c110d',
  manifestThemeColor: '#8c110d',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/lionlogo-black.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Don Bosco Lion Dance Team',
  subHeading: 'From the Holy Vietnamese Martyrs Church in Austin, Texas',
  // social
  socialLinks: [
    // {
    //   icon: 'fa-github',
    //   name: 'Github',
    //   url: 'https://github.com/anubhavsrivastava',
    // },
    // {
    //   icon: 'fa-twitter',
    //   name: 'Twitter',
    //   url: 'https://twitter.com/onlyanubhav',
    // },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/donboscoliondance/',
      shortName: 'Our Facebook Page',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/donboscoliondance',
      shortName: 'Follow us on Instagram',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'db.liondance@gmail.com',
    },
  ],
  phone: '000-00000',
  address: '',
};

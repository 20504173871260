import React, { useState, useEffect } from 'react';
import config from '../../config';
import Axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const Footer = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [allValid, setAllValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSucces] = useState(false);
  const [recaptchaPassed, setRecaptchaPassed] = useState(false);

  useEffect(() => {
    if (
      name !== '' &&
      emailValid &&
      message !== '' &&
      subject !== '' &&
      recaptchaPassed === true
    ) {
      setAllValid(true);
    } else {
      setAllValid(false);
    }
  }, [name, emailValid, message, subject, recaptchaPassed]);

  const validateEmail = email => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmail(email);
    setEmailValid(emailRex.test(email));
  };

  const handleSubmit = e => {
    const data = {
      name: name.trim(),
      email: email.trim(),
      subject: subject.trim(),
      message: message.trim(),
    };
    if (allValid) {
      setLoading(true);
      Axios.post('/.netlify/functions/sendEmail', JSON.stringify(data))
        .then(response => {
          if (response.status !== 200) {
            setLoading(false);
            console.log('error sending message');
          } else {
            setLoading(false);
            setSendSucces(true);
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
            console.log('message sent');
          }
        })
        .catch(error => console.log(error));
    }
    e.preventDefault();
  };

  return (
    <section id="contact">
      <div className="inner">
        <h2 className="major">Get in touch</h2>
        <p>
          If you would like us to perform for an event, or if you have any
          questions for us, please send us a message. We would love to hear from
          you!
        </p>
        <p className="note">
          Note: On weekdays we are only able to perform after school hours since
          many of our members are in school. Thank you for understanding!
        </p>
        <form method="post" action="/#">
          <div className="fields">
            <div className="field">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={e => validateEmail(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                name="subject"
                id="subject"
                value={subject}
                onChange={e => setSubject(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                id="message"
                rows="4"
                value={message}
                onChange={e => setMessage(e.target.value)}
              ></textarea>
            </div>
          </div>
          <ul className="actions">
            <li>
              <ReCAPTCHA
                sitekey="6LeqJ9AZAAAAAERsTifSgf6FOLV_LRmPalL5bUwX"
                onChange={() => {
                  setRecaptchaPassed(true);
                }}
              />
              <br />
              <input
                type="submit"
                value={
                  loading
                    ? 'Sending...'
                    : sendSuccess
                    ? 'Message Sent'
                    : 'Send Message'
                }
                onClick={e => handleSubmit(e)}
                className={allValid ? 'button' : 'button disabled'}
              />
            </li>
          </ul>
        </form>

        <ul className="contact">
          {/* <li className="fa-home">{config.address}</li> */}

          {/* <li className="fa-phone">{config.phone}</li> */}

          {config.socialLinks.map(social => {
            const { icon, url, shortName } = social;
            return (
              <li className={`${icon}`} key={url}>
                <a href={url}>{shortName || url}</a>
              </li>
            );
          })}
        </ul>
        <ul className="copyright">
          <li>&copy; {new Date().getFullYear()} Don Bosco Lion Dance </li>
        </ul>
      </div>
    </section>
  );
};

export default Footer;
